import { useAppDispatch } from "../../../../redux/hooks";

import CustomButton from "../../../../components/CustomButton";
import { getDownloadDestroyedProductsRaport } from "../../../../redux/api/inventory.api";
import { ProductsRaportsDestroyedInterface } from "../../../../redux/interfaces/inventory.interface";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import { TableItemId } from "../../../../components/tables/TableWidget";
import SvgIcon from "../../../../helpers/SvgIcon";

function ProductsDestroyedRaportsActionButton({
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: ProductsRaportsDestroyedInterface;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();

  const handleDownloadNrcd = () => {
    if (!crudData?.nr_raport) return;
    dispatch(
      getDownloadDestroyedProductsRaport({
        idRaport: crudData.nr_raport,
      }),
    );
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        tooltipTitle="Retipareste raport"
        className="mx-1 mb-1"
        onClick={handleDownloadNrcd}>
        <SvgIcon type="PRINT" />
      </CustomButton>
    </div>
  );
}

export default ProductsDestroyedRaportsActionButton;

import { useState } from "react";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import DepositStocksTransferModal from "./DepositStocksTransferModal";
import CustomButton from "../../../../components/CustomButton";
import EditProductModal from "./EditProductModal";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  postDeleteDepositStockProduct,
  postEditDepositStockProduct,
} from "../../../../redux/api/inventory.api";
import usePermissions from "../../../../router/usePermissions";
import { inventoryRoutes } from "../../../../router/routesConstants";
import userRoles from "../../../../constants/userRoles";
import SvgIcon from "../../../../helpers/SvgIcon";

interface DepositStocksActionButtonsProps {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  tableContext?: any;
}

function DepositStocksActionButtons({
  crudData,
  tableContext,
}: DepositStocksActionButtonsProps) {
  const dispatch = useAppDispatch();
  const { isAuthorized } = usePermissions();

  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const tableFilterData = {
    page: tableContext.tablePagination.page,
    perPage: tableContext.tablePagination.rowsPerPage,
    alley: tableContext.tableSearch?.alley,
    building: tableContext.tableSearch?.building,
    cell: tableContext.tableSearch?.cell,
    column: tableContext.tableSearch?.column,
    island: tableContext.tableSearch?.island,
    productCode: tableContext.tableSearch?.productCode,
    productName: tableContext.tableSearch?.productName,
    row: tableContext.tableSearch?.row,
    shelf: tableContext.tableSearch?.shelf,
  };

  const handleDeleteProduct = () => {
    dispatch(
      postDeleteDepositStockProduct({
        idActivCel: crudData.idActivCel,
        filters: tableFilterData,
      }),
    );
  };

  const handleSubmitEditProduct = (productNewValues: {
    idActivCel: number;
    codProdus: string;
    lot: string;
    dataExpirare: string;
    cantitate: number;
  }) => {
    dispatch(
      postEditDepositStockProduct({
        params: productNewValues,
        filters: tableFilterData,
      }),
    );
  };

  const goToProductHistory = () => {
    return `/${inventoryRoutes.inventory}/${inventoryRoutes.depositStocks}/${inventoryRoutes.productHistory}?codProdus=${crudData.codProdus}&lot=${crudData.lot}&dataExpirare=${crudData.dataExpirare}&numeProdusErp=${crudData.numeProdusErp}`;
  };

  return (
    <>
      {isSplitModalOpen && crudData && (
        <DepositStocksTransferModal
          productData={crudData}
          isSplitModalOpen={isSplitModalOpen}
          setIsSplitModalOpen={setIsSplitModalOpen}
          tableFilterData={tableFilterData}
        />
      )}
      {isEditModalOpen && crudData && (
        <EditProductModal
          productData={crudData}
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          handleSubmitEditProduct={handleSubmitEditProduct}
        />
      )}

      <div className="text-end">
        <CustomButton
          className="mt-1 me-1"
          variant="contained"
          color="primary"
          tooltipTitle="Transfera produs"
          onClick={() => setIsSplitModalOpen(true)}>
          <SvgIcon type="TRANSFER" className="svg-icon-2" />
        </CustomButton>
        {isAuthorized(userRoles.DEPOSIT_STOCKS_ACTION) && (
          <>
            <CustomButton
              className="mt-1 me-1"
              variant="contained"
              color="primary"
              tooltipTitle="Editeaza produs"
              onClick={() => setIsEditModalOpen(true)}>
              <SvgIcon type="EDIT" />
            </CustomButton>
            <CustomButton
              className="mt-1 me-1"
              variant="contained"
              color="primary"
              tooltipTitle="Istoric produs"
              navigate={goToProductHistory()}>
              <SvgIcon type="HISTORY" />
            </CustomButton>
          </>
        )}

        {isAuthorized(userRoles.DEPOSIT_STOCKS_DELETE) && (
          <CustomButton
            variant="contained"
            color="error"
            className="mt-1 me-1"
            withConfirmationModal={{
              modalLeftButtonVariant: "outlined",
              modalTitle: `Doriti sa stergeti din celula produsul: "${crudData.numeProdusErp}"?`,
              modalRightButtonOnClick: handleDeleteProduct,
            }}>
            <SvgIcon type="DELETE" />
          </CustomButton>
        )}
      </div>
    </>
  );
}

export default DepositStocksActionButtons;

export const initialMainRoute = "dashboard";

export const crudRoutes = {
  create: "creaza",
  edit: "edit",
};

export const receptionRoutes = {
  reception: "receptie",
  createReception: "creeaza-receptie",
  editReception: "editeaza-receptie",
  nrcd: "nrcd",
  quarantine: "carantina",
  previewQuarantine: "previzualizare-carantina",
  addInvoice: "adauga-factura",
  receivedProducts: "produse-receptionate",
  receivedProductsEdit: "editeaza-produs-receptionat",
  transferReceived: "marfa-receptionata",
  splitOperations: "transfer",
  transferTasks: "transfer-tasks",
  raports: "rapoarte",
  quarantineMonitor: "monitorizare-carantina",
  returns: "retururi",
  createReturn: "creeaza-retur",
  createReturnForm: "creeaza-formular-retur",
  returnDifferences: "diferente-retur",
  productsWithoutSn: "produse-fara-sn",
  productsWithCustomBc: "produse-bc-intern",
};

export const putawayRoutes = {
  putaway: "putaway",
  putawayRefill: "refill-putaway",
  putawayRearrangement: "rearanjare-putaway",
  putawayOperations: "operatiuni-putaway",
};

export const pickingRoutes = {
  picking: "picking",
  verifyCommand: "verifica-comanda",
  printCommandInvoice: "printeaza-comanda",
  deliveryArrangement: "dispozitie-livrare",
  splitInvoices: "split-invoices",
  verificationZones: "verificare-zone",
  commandHistory: "istoric-comanda",
  editVerificationZone: "editare-verificare-zona",
  createVerificationZone: "creare-verificare-zona",
};

export const avizPickingRoutes = {
  avizPicking: "avizPicking",
  avizVerifyCommand: "verifica-comanda-aviz",
  avizPrintCommandInvoice: "printeaza-comanda-aviz",
  avizDeliveryArrangement: "dispozitie-livrare-aviz",
  avizVerificationZones: "verificare-zone-aviz",
  avizCommandHistory: "istoric-comanda-aviz",
};

export const expeditionRoutes = {
  expedition: "expeditie",
  scheduleWorkingPoints: "puncte-de-lucru",
  scheduleWorkingRoute: "program-rute",
  organize: "organizare",
  nodes: "noduri",
  editNodesRoutes: "noduri/editare",
  incompleteInvoices: "facturi-neconforme",
  scanPlasticBoxes: "scanare-cutii-plastic",
  monitoringPlasticBoxes: "monitorizare-cutii-plastic",
  borderouHistory: "istoric-borderou",
  searchPackageByOp: "Istoric-scanare-colet",
};

export const inventoryRoutes = {
  inventory: "inventar",
  receivedProducts: "produse-receptionate",
  depositStocks: "stocuri",
  nomenclature: "nomenclator",
  editProduct: "editeaza-produs",
  editPackage: "editeaza-ambalaj",
  editLimit: "editeaza-limite",
  copyProduct: "copiaza-produs",
  productsThatExpireSoon: "produse-care-expira-curand",
  productsDestructionProcedure: "procedura-distrugere-produse",
  productsDestroyedRaports: "rapoarte-produse-distruse",
  productHistory: "istoric-produs",
  duplicateProducts: "produse-duplicate",
  returningProductsToProvider: "returnarea-produselor-catre-furnizor",
};

export const reportsRoutes = {
  reports: "rapoarte",
  usersActivity: "activitate-utilizatori",
  usersExtraActivities: "extra-activitati-utilizatori",
  quarantineProductRecords: "evidenta-produselor-aflate-in-carantina",
  returningProductsToProviderReports:
    "rapoarte-returnare-produse-catre-furnizor",
};

export const adminRoutes = {
  users: "utilizatori",
  createUser: "creaza",
  editUser: "editeaza",
  adminUserWebRoles: "roluri-web",
  adminUserMobileRoles: "roluri-mobile",
  cellsNomenclature: "nomenclator-celule",
  editCellNomenclature: "editare-celula-nomenclator",
  createCellNomenclature: "creare-celula-nomenclator",
};

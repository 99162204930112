import { useAppDispatch } from "../../../../../redux/hooks";
import {
  fetchDeleteVerificationZone,
  fetchUnlockZone,
} from "../../../../../redux/api/picking.api";

import CustomButton from "../../../../../components/CustomButton";
import { pickingRoutes } from "../../../../../router/routesConstants";
import { VerificationZonesListInterface } from "../../../../../redux/interfaces/picking.interface";
import usePermissions from "../../../../../router/usePermissions";
import userRoles from "../../../../../constants/userRoles";
import SvgIcon from "../../../../../helpers/SvgIcon";

function VerificationZonesActionButton({
  itemId,
  crudData,
}: {
  itemId: string;
  crudData: VerificationZonesListInterface;
}) {
  const dispatch = useAppDispatch();
  const { isAuthorized } = usePermissions();

  const { idZona, deleted } = crudData;

  const handleActivateCell = () => {
    dispatch(
      fetchUnlockZone({
        idZona: idZona,
      }),
    );
    return;
  };

  const handleActivateOrDeactivateVerificationZone = () => {
    dispatch(
      fetchDeleteVerificationZone({
        idZona: idZona,
      }),
    );
  };

  const goToUpdateVerificationZone = () => {
    if (idZona) {
      return `${itemId}/${pickingRoutes.editVerificationZone}?idZona=${idZona}`;
    }
  };

  return (
    <div>
      {isAuthorized(userRoles.EDIT_VERIFICATION_ZONE) && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Editeaza"
          navigate={goToUpdateVerificationZone()}>
          <SvgIcon type="EDIT" />
        </CustomButton>
      )}
      <CustomButton
        variant="contained"
        color={!deleted ? "error" : "success"}
        className="mt-1 me-1"
        tooltipTitle={!deleted ? "Dezactiveaza" : "Activeaza"}
        withConfirmationModal={{
          modalLeftButtonVariant: "outlined",
          modalTitle: `Doriti sa ${
            !deleted ? "dezactivati" : "activati"
          } zona ${crudData.codZona}?`,
          modalRightButtonOnClick: handleActivateOrDeactivateVerificationZone,
        }}>
        <SvgIcon type={"ACTIVATE"} fill={"white"} className="svg-icon-3" />
      </CustomButton>

      {crudData.status && (
        <CustomButton
          variant="contained"
          color="success"
          className="mt-1 me-1"
          tooltipTitle={"Deblocheaza"}
          withConfirmationModal={{
            modalLeftButtonVariant: "outlined",
            modalTitle: `Doriti sa deblocati zona ${crudData.codZona}?`,
            modalRightButtonOnClick: handleActivateCell,
          }}>
          <SvgIcon type="LOCK_OPEN" fill="white" className="svg-icon-3" />
        </CustomButton>
      )}
    </div>
  );
}

export default VerificationZonesActionButton;

import TableWidget from "../../../components/tables/TableWidget";
import { getScheduleWorkingPoints } from "../../../redux/api/expedition.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

import {
  getExpeditionScheduleWorkingPointsTableData,
  expeditionScheduleWorkingPointsTableHeader,
} from "./components/tableConfigs/expeditionScheduleWorkingPointsTableConfig";
import PickingScheduleWorkingPointsTableActionButtons from "./components/TableActionsButtonsComponents/ExpeditionScheduleWorkingPointsTableActionButtons";

function ExpeditionScheduleWorkingPoints() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      scheduleWorkingPoints: {
        data: scheduleWorkingPointsData,
        isLoadingScheduleWorkingPoints,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        getScheduleWorkingPoints({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          deliveryPoint: action.tableSearch?.deliveryPoint ?? "",
          route: action.tableSearch?.route ?? "",
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle={"Puncte de lucru"}
      tableHeaderData={expeditionScheduleWorkingPointsTableHeader}
      tableItemsData={getExpeditionScheduleWorkingPointsTableData(
        scheduleWorkingPointsData?.workingPoints,
      )}
      handleTableActions={handleTableActions}
      totalItems={scheduleWorkingPointsData.nrTotal}
      tableLoading={isLoadingScheduleWorkingPoints}
      ActionButtonsComponent={PickingScheduleWorkingPointsTableActionButtons}
      borderedRow
    />
  );
}

export default ExpeditionScheduleWorkingPoints;

import { useAppDispatch } from "../../../../redux/hooks";

import CustomButton from "../../../../components/CustomButton";
import { ReturningProductsToProviderReportsInterface } from "../../../../redux/interfaces/inventory.interface";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { getDownloadReturningProductsToProviderRaport } from "../../../../redux/api/reports.api";
import SvgIcon from "../../../../helpers/SvgIcon";

function ReturningProductsReportsActionButton({
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: ReturningProductsToProviderReportsInterface;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();

  const handleDownloadNrcd = () => {
    if (!crudData?.nr_raport) return;
    dispatch(
      getDownloadReturningProductsToProviderRaport({
        idRaport: crudData.nr_raport,
        dataPV: crudData.dataPV,
      }),
    );
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        tooltipTitle="Retipareste raport"
        className="mx-1 mb-1"
        onClick={handleDownloadNrcd}>
        <SvgIcon type="PRINT" />
      </CustomButton>
    </div>
  );
}

export default ReturningProductsReportsActionButton;

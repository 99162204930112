import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { postMonitoringPlasticBoxes } from "../../../redux/api/expedition.api";
import TableWidget from "../../../components/tables/TableWidget";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import {
  getMonitoringPlasticBoxesTableData,
  monitoringPlasticBoxesTableHeader,
} from "./tableConfig/monitoringPlasticBoxesTableConfig";
// import MonitorPlasticBoxesHeader from "./components/MonitorPlasticBoxesHeader";

function MonitoringPlasticBoxes() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      monitoringPlasticBoxes: {
        data: { cutiiPlastic, nrTotal },
        isLoadingMonitoringPlasticBoxes,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter &&
      action.tableSearch
    ) {
      dispatch(
        postMonitoringPlasticBoxes({
          params: {
            page: action.tablePagination.page,
            per_page: action.tablePagination.rowsPerPage,
            status: action.tableFilter?.status,
            pctDeLivr: action.tableSearch?.pctDeLivr,
            ruta: action.tableSearch?.ruta,
            sn: action.tableSearch?.sn,
          },
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle="Monitorizare cutii plastic"
      tableHeaderData={monitoringPlasticBoxesTableHeader}
      // CustomHeaderComponent={MonitorPlasticBoxesHeader}
      tableItemsData={getMonitoringPlasticBoxesTableData(cutiiPlastic)}
      handleTableActions={handleTableActions}
      tableLoading={isLoadingMonitoringPlasticBoxes}
      borderedRow
      totalItems={nrTotal}
    />
  );
}

export default MonitoringPlasticBoxes;

import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { BorderouHistoryListInterface } from "../../../../redux/interfaces/expedition.interface";
import { dateToString } from "../../../../utils/dateAndTime";

export const borderouHistoryTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Id borderou",
    value: "idBorderou",
    minW: 125,
  },
  {
    label: "Nod",
    value: "nod",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Data creare borderou",
    value: "createdAt",
    minW: 125,
  },
];

const splitExtraItems = (item: BorderouHistoryListInterface) => {
  const splittedItemsRows: Array<{ title: string }[]> = [];
  let currentRowItems: { title: string }[] = [];

  item.borderouri.forEach((borderou, _, borderuri) => {
    if (borderuri.length === 1) {
      splittedItemsRows.push([{ title: borderou }]);
      return;
    }
    if (currentRowItems.length < borderouHistoryTableHeader.length + 1) {
      currentRowItems.push({ title: borderou });
    } else {
      splittedItemsRows.push(currentRowItems);
      currentRowItems = [{ title: borderou }];
    }
  });

  if (currentRowItems.length > 0) {
    splittedItemsRows.push(currentRowItems);
  }

  return splittedItemsRows;
};

export const getBorderouHistoryTableData: any = (
  borderouHistory: BorderouHistoryListInterface[],
) =>
  borderouHistory.map((item) => {
    return {
      id: item.idBorderou,
      crudData: item,
      extraData: splitExtraItems(item),
      data: [
        {
          title: item.idBorderou,
        },
        {
          title: item.nod ?? "-",
        },
        {
          badges: [
            {
              badgeText: dateToString(item.createdAt),
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });

import React, { useEffect } from "react";
import { getUsersActivityReport } from "../../../redux/api/reports.api";
import { fetchUsers } from "../../../redux/api/admin.api";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import TableWidget from "../../../components/tables/TableWidget";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

import {
  getUsersActivityTableData,
  usersActivityTableHeader,
} from "./tableConfigs/usersActivityTableConfig";

import DropdownsPageHeader from "./components/DropdownsPageHeader";
import { clearUsersActivity } from "../../../redux/reducer/reports.reducer";

function UsersActivity() {
  const dispatch = useAppDispatch();

  const {
    reports: {
      usersActivity: {
        data: { data: usersActivity, total },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    reports: state.reports,
  }));

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: 1,
        perPage: 10000,
      }),
    );
  }, [dispatch]);

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      if (
        action.tableSearch.selectedMonth &&
        action.tableSearch.selectedYear &&
        action.tableSearch.selectedUsers &&
        action.tableSearch.selectedRoles
      ) {
        dispatch(
          getUsersActivityReport({
            page: action.tablePagination.page,
            per_page: action.tablePagination.rowsPerPage,
            selectedUsers: JSON.parse(action.tableSearch.selectedUsers),
            selectedRoles: JSON.parse(action.tableSearch.selectedRoles),
            month: action.tableSearch.selectedMonth,
            year: action.tableSearch.selectedYear,
            day: parseInt(action.tableSearch.selectedDay),
          }),
        );
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearUsersActivity());
    };
  }, [dispatch]);

  return (
    <TableWidget
      tableHeaderData={usersActivityTableHeader}
      tableItemsData={getUsersActivityTableData(usersActivity)}
      handleTableActions={handleTableActions}
      CustomHeaderComponent={DropdownsPageHeader}
      totalItems={total}
      tableLoading={isLoading}
      withGlobalSearch={false}
      withGlobalFilter={false}
    />
  );
}

export default UsersActivity;

import { ProductHistoryInterface } from "../../../../redux/interfaces/inventory.interface";

import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";

import { getDateAndTime } from "../../../../utils/dateAndTime";

const taskTypes = [
  {
    value: 0,
    label: "Admin",
  },
  {
    value: 1,
    label: "Task putaway la celula (intrare dupa receptie)",
  },
  {
    value: 2,
    label: "Task rearanjare in  celule (operatie interna)",
  },
  {
    value: 3,
    label: "Task refill in celule (operatie interna)",
  },
  {
    value: 4,
    label: "Iesire produs prin comanda client cu factura",
  },
  {
    value: 5,
    label: "Iesire produs prin comanda client cu aviz",
  },
  {
    value: 6,
    label: "Iesire produs predat in vederea distrugerii",
  },
  {
    value: 7,
    label: "Iesire produs ca retur catre furnizor",
  },
  {
    value: 8,
    label: "Iesire produs catre furnizor ca urmare a unei retrageri notificata",
  },
  {
    value: 9,
    label: "Iesire produs prin vanzare online (factura)",
  },
  {
    value: 10,
    label: "Stergere stocuri",
  },
  {
    value: 11,
    label: "Inventar periodic",
  },
  {
    value: 12,
    label: "Editare produs",
  },
];

const getTaskTypesLabel = (status: number) => {
  const getStatus = taskTypes.find((item) => item.value === status);
  return getStatus ? getStatus.label : "";
};

export const productHistoryTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Cantitate miscata",
    value: "cantMiscata",
    minW: 50,
  },
  {
    label: "Cantitatea in celula",
    value: "totalCantitate",
    minW: 50,
  },
  {
    label: "Locatie",
    value: "location",
    minW: 100,
  },
  {
    label: "Utilizator",
    value: "userName",
    minW: 100,
  },
  {
    label: "Punct de livrare",
    value: "pctDeLivr",
    minW: 100,
  },
  {
    label: "Tip task",
    value: "taskType",
    minW: 100,
  },
  {
    label: "Data actiune",
    value: "dataActiune",
    minW: 100,
  },
];

export const getProductHistoryTableData: any = (
  productHistoryList: ProductHistoryInterface[],
) =>
  productHistoryList?.map((product) => {
    const location = [
      product.cladire,
      product.rand,
      product.coloana,
      product.polita,
      product.celula,
      product.alee,
      product.insula,
    ]
      .filter(Boolean)
      .join("-");
    return {
      id: product.id_arh_activCel || product.idActivCel,
      crudData: product,
      data: [
        {
          title: product.cantMiscata,
        },
        {
          title: product.cantitate,
        },
        {
          title: location,
        },
        {
          title: product.userName,
        },
        {
          title: product.pctDeLivr,
        },
        {
          badges: [
            {
              badgeText: getTaskTypesLabel(product.taskType),
              badgeColor: "light-primary",
            },
          ],
        },
        {
          badges: [
            {
              badgeText: getDateAndTime(product.dataActiune),
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });

import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { SearchPackageByOpInterface } from "../../../../redux/interfaces/expedition.interface";

import { getDateAndTime } from "../../../../utils/dateAndTime";

export const searchPackageByOpTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Nume",
    value: "op",
    minW: 125,
    withSearch: {
      placeholder: "Cauta dupa nr. comanda",
    },
  },
  {
    label: "Prenume",
    value: "prenume",
    minW: 125,
  },
  {
    label: "Username",
    value: "userName",
    minW: 125,
  },

  {
    label: "Data scanarii",
    value: "actionTime",
    minW: 125,
  },
];

export const getSearchPackageByOpTableData: any = (
  scannedPackageList: SearchPackageByOpInterface[],
) =>
  scannedPackageList?.map((item) => {
    return {
      id: item.idLegCutie,
      crudData: item,
      data: [
        {
          title: item.nume,
        },
        {
          title: item.prenume,
        },
        {
          title: item.userName,
        },
        {
          badges: [
            {
              badgeText: getDateAndTime(item.actionTime ?? "-"),
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });

import React, { useCallback } from "react";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { VerifyCommandProductsListInterface } from "../../../../../../redux/interfaces/picking.interface";
import { setVerifyCommandProductsList } from "../../../../../../redux/reducer/picking.reducer";
import ProductCardComponent from "./ProductCardComponent";
import { QrCodeObjectInterface } from "../../../../../../helpers/qrCodeFormater";

function VerifyCommandTable({
  verifyCommandProductsList,
  verificationZone,
  qrCodeParams,
  handleFocusProductScannedInput,
}: {
  verifyCommandProductsList: VerifyCommandProductsListInterface[];
  verificationZone: string;
  qrCodeParams: QrCodeObjectInterface | null;
  handleFocusProductScannedInput: () => void;
}) {
  const dispatch = useAppDispatch();

  const handleValidateProduct = useCallback(
    (
      product: VerifyCommandProductsListInterface,
      withScanProductAutoFocus?: boolean,
    ) => {
      dispatch(
        setVerifyCommandProductsList({
          idPozitiePicking: product.idPozitiePicking,
          changes: { isValidate: !product.isValidate },
        }),
      );
      if (withScanProductAutoFocus) {
        handleFocusProductScannedInput();
      }
    },
    [dispatch, handleFocusProductScannedInput],
  );

  return (
    <div className="table-responsive">
      <table className="table table-row-gray-100 align-middle gs-0 gy-3">
        <thead>
          <tr className="fw-bolder text-muted bg-light">
            <th className="ps-2 rounded-start">Valid</th>
            <th>Nume</th>
            <th style={{ width: 50 }}>Cantitate</th>
            <th>Lot</th>
            <th style={{ width: 110 }}>Data expirare</th>
            <th style={{ width: 200 }}>Bax</th>
            <th>Celula</th>
            <th className="rounded-end"></th>
          </tr>
        </thead>
        <tbody>
          {verifyCommandProductsList?.map((product) => (
            <ProductCardComponent
              key={product.idPozitiePicking}
              commandCode={verificationZone}
              product={product}
              qrCodeParams={qrCodeParams}
              handleValidateProduct={handleValidateProduct}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default VerifyCommandTable;

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiRequest,
  errorFormatHandler,
  isDevelop,
} from "../../helpers/apiHelper";
import {
  DestroyedProductsType,
  ReturningProductsType,
} from "../interfaces/inventory.interface";
import { getDownloadReturningProductsToProviderRaport } from "./reports.api";

const apiEnv = isDevelop
  ? process.env.REACT_APP_TEST_API_URL
  : process.env.REACT_APP_PUBLIC_API_URL;

export const getReceivedProducts = createAsyncThunk(
  "/inventory/receivedProducts",
  async (
    filters: {
      page?: number;
      perPage?: number;
      startDate?: string;
      endDate?: string;
      numeProdus?: string;
      numeFurnizor?: string;
      lot?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, startDate, endDate } = filters;

    try {
      const { data } = await apiRequest(
        `/produseReceptionate?page=${page ?? 1}&per_page=${
          perPage ?? 5
        }&startDate=${startDate}&endDate=${endDate}&numeProdus=${
          filters.numeProdus ?? ""
        }&numeFurnizor=${filters.numeFurnizor ?? ""}&lot=${filters.lot ?? ""}
            `,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/inventory/receivedProducts", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

interface GetDepositStocksInterface {
  page?: number;
  perPage?: number;
  building?: string;
  row?: string;
  column?: string;
  shelf?: string;
  cell?: string;
  alley?: string;
  island?: string;
  productCode?: string;
  productName?: string;
  lot?: string;
}

export const getDepositStocks = createAsyncThunk(
  "/inventory/depositStocks",
  async (filters: GetDepositStocksInterface, { rejectWithValue, dispatch }) => {
    const {
      page = "",
      perPage = "",
      alley = "",
      building = "",
      cell = "",
      column = "",
      island = "",
      productCode = "",
      productName = "",
      row = "",
      shelf = "",
      lot = "",
    } = filters;

    try {
      const { data } = await apiRequest(
        `/stocDepozit?page=${page ?? 1}&per_page=${
          perPage ?? 5
        }&cladire=${building}&rand=${row}&coloana=${column}&polita=${shelf}&celula=${cell}&alee=${alley}&insula=${island}&codProdus=${productCode}&numeProdusErp=${productName}&lot=${lot}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/inventory/depositStocks", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postDepositStocksTransfer = createAsyncThunk(
  "/inventory/transferMarfaDepozit",
  async (
    receptionData: {
      product: object;
      filters: GetDepositStocksInterface;
      deposits: any[];
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { filters, product, deposits, callBack } = receptionData;
    try {
      const transferObject = {
        product,
        deposits,
      };
      await apiRequest(`/transferMarfaDepozit`, "POST", transferObject);
      dispatch(getDepositStocks(filters));
      callBack && callBack();
    } catch (err: any) {
      console.warn("/inventory/transferMarfaDepozit", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postDeleteDepositStockProduct = createAsyncThunk(
  "/inventory/deleteProduct",
  async (
    transferData: {
      filters: GetDepositStocksInterface;
      idActivCel: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { idActivCel, filters } = transferData;
    try {
      await apiRequest(`/deleteStocActivCel?idActivCel=${idActivCel}`, "GET");
      dispatch(getDepositStocks(filters));
    } catch (err: any) {
      console.warn("/inventory/deleteProduct", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postEditDepositStockProduct = createAsyncThunk(
  "/inventory/editProduct",
  async (
    transferData: {
      filters: GetDepositStocksInterface;
      params: {
        idActivCel: number;
        codProdus: string;
        lot: string;
        dataExpirare: string;
        cantitate: number;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    const { params, filters } = transferData;
    try {
      await apiRequest("/editStocActivCel", "POST", params);
      dispatch(getDepositStocks(filters));
    } catch (err: any) {
      console.warn("/inventory/editProduct", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postGetProducts = createAsyncThunk(
  "/inventory/postGetProducts",
  async (
    transferData: {
      page: number;
      per_page: number;
      numeProdusErp?: string;
      codProdusErp?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/produseWms", "POST", transferData);
      return data;
    } catch (err: any) {
      console.warn("/inventory/postGetProducts", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getSelectProductWms = createAsyncThunk(
  "/inventory/getSelectProductWms",
  async (idProdus: number, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/selectProdusWms/${idProdus}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/inventory/getSelectProductWms", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postEditProdusWms = createAsyncThunk(
  "/inventory/postEditProdusWms",
  async (transferData: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest("/editProdusWms", "POST", transferData);
      return data;
    } catch (err: any) {
      console.warn("/inventory/postEditProdusWms", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCopyProductNomenclature = createAsyncThunk(
  "/inventory/postCopyProductNomenclature",
  async (transferData: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(
        "/copyProductNomenclature",
        "POST",
        transferData,
      );
      return data;
    } catch (err: any) {
      console.warn("/inventory/postCopyProductNomenclature", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postDeactivateProductWms = createAsyncThunk(
  "/inventory/deactivateProduct",
  async (
    transferData: {
      id: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/deactivateProduct",
        "POST",
        transferData,
      );
      return data;
    } catch (err: any) {
      console.warn("/inventory/deactivateProduct", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getProductsThatExpire = createAsyncThunk(
  "/inventory/productsThatExpire",
  async (
    params: {
      page: number;
      perPage: number;
      typeFilter: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage, typeFilter } = params;
      const { data: responseData } = await apiRequest(
        `/productsNextExpired?page=${page}&per_page=${perPage}&typeFilter=${typeFilter}`,
        "GET",
      );
      return responseData;
    } catch (err: any) {
      console.warn("/inventory/productsThatExpire", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getQuarantinedProductsToDestroy = createAsyncThunk(
  "/inventory/quarantinedProductsToDestroy",
  async (
    params: {
      page: number;
      perPage: number;
      reason?: number;
      product?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { perPage } = params;
      const { data: responseData } = await apiRequest(
        "/prodCarantDistrugere",
        "GET",
        { ...params, per_page: perPage },
      );
      return responseData;
    } catch (err: any) {
      console.warn("/inventory/quarantinedProductsToDestroy", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getDownloadDestroyedProductsRaport = createAsyncThunk(
  "/inventory/getDownloadDestroyedProductsRaport",
  async (
    {
      idRaport,
    }: {
      idRaport: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window
        .open(`${apiEnv}/downloadDestroyedProductsRaport/${idRaport}`, "_blank")
        ?.focus();
    } catch (err: any) {
      console.warn("/inventory/getDownloadDestroyedProductsRaport", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postProdToDestroy = createAsyncThunk(
  "/inventory/prodToDestroy",
  async (
    params: {
      productsToDestroy: DestroyedProductsType[];
      page: number;
      perPage: number;
      reason?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/destroyProductsCarant",
        "POST",
        params,
      );
      dispatch(getQuarantinedProductsToDestroy(params));
      dispatch(getDownloadDestroyedProductsRaport({ idRaport: data.idRaport }));
      return data;
    } catch (err: any) {
      console.warn("/inventory/prodToDestroy", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getProductsDestroyedRaports = createAsyncThunk(
  "/inventory/productsDestroyedRaports",
  async (
    params: {
      page: number;
      per_page: number;
      user: string;
      dataPV: string;
      productName: string;
      lot: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: responseData } = await apiRequest(
        `/prodDestroyedRaports`,
        "GET",
        params,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/inventory/productsDestroyedRaports", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getProductHistory = createAsyncThunk(
  "/inventory/productHistory",
  async (
    params: {
      page: number;
      per_page: number;
      codProdus: string;
      lot: string;
      dataExpirare: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: responseData } = await apiRequest(
        `/productHistory`,
        "GET",
        params,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/inventory/productHistory", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getDuplicateProducts = createAsyncThunk(
  "/inventory/duplicateProducts",
  async (
    params: {
      page: number;
      per_page: number;
      type: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: responseData } = await apiRequest(
        `/duplicateProducts`,
        "GET",
        params,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/inventory/duplicateProducts", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getQuarantinedProductsToReturn = createAsyncThunk(
  "/inventory/quarantinedProductsToReturn",
  async (
    params: {
      page: number;
      perPage: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage } = params;
      const { data: responseData } = await apiRequest(
        `/prodCarantReturnare?page=${page}&per_page=${perPage}`,
        "GET",
      );
      return responseData;
    } catch (err: any) {
      console.warn("/inventory/quarantinedProductsToReturn", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postProdToReturn = createAsyncThunk(
  "/inventory/prodToReturn",
  async (
    params: {
      productsToReturn: ReturningProductsType[];
      page: number;
      perPage: number;
      docType: string | number;
      provider: string | number;
      docNrAndDate: string | number | null;
      nrPV: number | null;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/returningProdToProvider",
        "POST",
        params,
      );
      dispatch(getQuarantinedProductsToReturn(params));
      dispatch(
        getDownloadReturningProductsToProviderRaport({
          idRaport: data.nrPV,
          dataPV: data.dataPV,
        }),
      );

      return data;
    } catch (err: any) {
      console.warn("/inventory/prodToReturn", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

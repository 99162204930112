import { memo } from "react";

import { useAppDispatch } from "../../../../redux/hooks";
import {
  getDownloadNrcd,
  getRenumberProducts,
} from "../../../../redux/api/receipt.api";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";

import { TableItemId } from "../../../../components/tables/TableWidget";
import { receptionRoutes } from "../../../../router/routesConstants";
import { dateToString } from "../../../../utils/dateAndTime";
import CustomButton from "../../../../components/CustomButton";
import SvgIcon from "../../../../helpers/SvgIcon";

function ReceptionTableActionButtons({
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();

  const itemStatusArr = crudData.statusZona
    .split(",")
    .map((item: any) => parseInt(item));

  const buttonNrcdEnabled = crudData?.buttonNrcdEnabled;
  const buttonQuarantineEnabled = crudData?.buttonQuarantineEnabled;
  const showProductRenumberingButton = itemStatusArr.includes(3);

  const buttonPreviewNrcdEnabled =
    itemStatusArr.includes(2) &&
    itemStatusArr.includes(3) &&
    itemStatusArr[itemStatusArr.length - 1] < 4;

  const receivedAt = dateToString(crudData?.createdAt);

  const handleEditReception = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.editReception}?idFurnizor=${crudData?.idFurnizor}&idFlux=${crudData?.idFluxReceptie}`;
  };

  const addInvoice = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.addInvoice}?idFurnizor=${crudData?.idFurnizor}&idFlux=${crudData?.idFluxReceptie}`;
  };

  const goToNrcd = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.nrcd}?reception=${crudData.idFluxReceptie}&createdAt=${crudData.createdAt}`;
  };

  const goToPreviewQuarantine = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.previewQuarantine}?reception=${crudData.idFluxReceptie}&createdAt=${crudData.createdAt}`;
  };

  const goToQuarantine = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.quarantine}?reception=${crudData.idFluxReceptie}&createdAt=${crudData.createdAt}`;
  };

  const goToProducts = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.receivedProducts}?reception=${crudData.idFluxReceptie}&data=${receivedAt}`;
  };

  const handleDownloadNrcd = () => {
    dispatch(
      getDownloadNrcd({
        nrcdId: crudData.idFluxReceptie,
        furnizor: crudData.numeFurnizor,
        data: receivedAt,
      }),
    );

    return;
  };

  const handleRenumberingProducts = () => {
    const callBack = () => {
      // tableContext.setTablePagination((prev: any) => ({ ...prev, page: 1 }));
    };
    dispatch(
      getRenumberProducts({
        idFluxReceptie: crudData.idFluxReceptie,
        callBack,
      }),
    );
  };

  return (
    <div className="text-start">
      {/* start icons */}
      <CustomButton
        variant="contained"
        color="primary"
        tooltipTitle="Produse receptionate"
        className="mx-1 mb-1"
        navigate={goToProducts()}>
        <SvgIcon type="RECEIVED_PRODUCTS" fill="white" />
      </CustomButton>

      <CustomButton
        variant="contained"
        tooltipTitle="Adauga factura"
        className="mx-1 mb-1"
        navigate={addInvoice()}>
        <SvgIcon type="ADD_INVOICE" fill="white" />
      </CustomButton>

      <CustomButton
        variant="contained"
        tooltipTitle="Vizualizare NIR"
        disabled={!buttonNrcdEnabled}
        className="mx-1 mb-1"
        navigate={goToNrcd()}>
        <SvgIcon type="NIR_VISUALIZATION" fill="white" />
      </CustomButton>

      <CustomButton
        variant="contained"
        disabled={!buttonPreviewNrcdEnabled}
        tooltipTitle="Previzualizare neconformitati"
        className="mx-1 mb-1"
        navigate={goToPreviewQuarantine()}>
        <SvgIcon type="PREVIEW_NONCONFORMITIES" fill="white" />
      </CustomButton>

      <CustomButton
        variant="contained"
        color="secondary"
        tooltipTitle="Vezi neconformitati"
        disabled={!buttonQuarantineEnabled}
        className="mx-1 mb-1"
        navigate={goToQuarantine()}>
        <SvgIcon type="PREVIEW_NONCONFORMITIES" fill="white" />
      </CustomButton>

      <CustomButton
        variant="contained"
        tooltipTitle="Descarca PV neconformitati"
        className="mx-1 mb-1"
        onClick={handleDownloadNrcd}>
        <SvgIcon type="DOCUMENT_WITH_DOWNLOAD" fill="white" />
      </CustomButton>

      <CustomButton
        variant="contained"
        tooltipTitle="Editeaza receptia"
        className="mx-1 mb-1"
        navigate={handleEditReception()}>
        <SvgIcon type="EDIT" fill="white" />
      </CustomButton>

      <CustomButton
        variant="contained"
        tooltipTitle="Deblocare receptie"
        className="mx-1 mb-1"
        disabled={buttonNrcdEnabled || !showProductRenumberingButton}
        withConfirmationModal={{
          modalTitle: `Vreti sa incepeti renumararea produselor pentru ${crudData.numeFurnizor}?`,
          modalSubtitle: "Acest proces poate dura cateva minute",
          modalLeftButtonText: "Nu",
          modalRightButtonText: "Da",
          modalLeftButtonColor: "primary",
          modalRightButtonColor: "error",
          modalRightButtonOnClick: handleRenumberingProducts,
        }}>
        <SvgIcon type="LOCK_OPEN" fill="white" className="svg-icon-2" />
      </CustomButton>
    </div>
  );
}

export default memo(ReceptionTableActionButtons);

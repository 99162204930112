import React, { memo, useCallback } from "react";
import useTableCtx from "../tableContext/useTableCtx";
import TableItem from "./TableItem";
import TableItemExtra from "../TableItemExtra";
import Text from "../../../styleguide/Text";

function TableBody({
  ActionButtonsComponent,
  emptyTableLabel,
}: {
  ActionButtonsComponent: any;
  emptyTableLabel: string;
}) {
  const tableContext = useTableCtx()!;

  const { tableData, tableHeader } = tableContext;

  const getActionButtonsComponent = useCallback((props: any) => {
    if (!ActionButtonsComponent) {
      return null;
    }
    return <ActionButtonsComponent {...props} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <tbody>
      {tableData?.length === 0 && (
        <tr>
          {tableHeader.map((_, idx, headerItems) => {
            const halfLengthIndex =
              Math.ceil(headerItems.length / 2) === idx + 1;
            return (
              <td key={`header-${idx}`}>
                {halfLengthIndex && (
                  <div className="d-flex align-items-center justify-content-center mt-8">
                    <Text variant="h2">{emptyTableLabel}</Text>
                  </div>
                )}
              </td>
            );
          })}
        </tr>
      )}

      {tableData?.length > 0 &&
        tableData?.map(({ id, data, crudData, extraData }, idx) => {
          return (
            <React.Fragment key={id}>
              <TableItem
                tableItemId={id}
                crudData={crudData}
                tableItemData={data}
                idx={idx}
                ActionButtonsComponent={getActionButtonsComponent}
              />
              {extraData?.map((extraDataItem, idx) => {
                return <TableItemExtra extraData={extraDataItem} key={idx} />;
              })}
            </React.Fragment>
          );
        })}
    </tbody>
  );
}

export default memo(TableBody);

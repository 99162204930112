import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { ProductsWithoutSNListInterface } from "../../../../redux/interfaces/receipt.interface";

export const productsWithoutSnTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Nume produs",
    value: "numeProdus",
    minW: 125,
    withSearch: {
      placeholder: "Cauta nume produs",
    },
  },
  {
    label: "Cod produs",
    value: "codProdus",
    minW: 125,
    withSearch: {
      placeholder: "Cauta cod produs",
    },
  },
  {
    label: "Nume grupa",
    value: "numeGrupa",
    minW: 125,
  },
];

export const getProductsWithoutSnTableData = (
  data: ProductsWithoutSNListInterface[],
): any =>
  data.map((item) => {
    return {
      id: item.idProdusErp,
      crudData: item,
      data: [
        {
          title: item.numeProdus,
        },
        {
          title: item.codProdus,
        },
        {
          title: item.numeGrupa,
        },
      ],
    };
  });

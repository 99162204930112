import { TableHeaderCategoryConfig } from "../../../components/tables/tableContext/TableContext";
import { ProductsWithCustomSnListInterface } from "../../../redux/interfaces/receipt.interface";
import { dateToString } from "../../../utils/dateAndTime";

const productTypeConstants = [
  { value: 1, label: "Produs fara sn" },
  { value: 2, label: "Produs din AIP" },
  { value: 3, label: "Produs din IP" },
];

const getStatusLabel = (status: any) => {
  const currentStatus = productTypeConstants.find(
    (item) => item.value === parseInt(status),
  );

  return currentStatus?.value
    ? `${currentStatus?.value}. ${currentStatus?.label}`
    : "";
};

export const productsWithCustomBcTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Id produs Erp",
    value: "idProdusErp",
    minW: 125,
  },
  {
    label: "Nume produs",
    value: "numeProdus",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Cod produs",
    value: "codProdus",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Nume grupa",
    value: "numeGrupa",
    minW: 125,
  },
  {
    label: "SN",
    value: "sn",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Tip",
    value: "tip",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Data creare/actualizare",
    value: "created_at",
    minW: 125,
  },
];

export const getProductsWithCustomBcTableData = (
  data: ProductsWithCustomSnListInterface[],
): any =>
  data.map((item) => {
    return {
      id: item.id,
      crudData: item,
      data: [
        {
          title: item.idProdusErp,
        },
        {
          title: item.numeProdus,
        },
        {
          title: item.codProdus,
        },
        {
          title: item.numeGrupa,
        },
        {
          title: item.sn,
        },
        {
          badges: [
            {
              badgeText: getStatusLabel(item.tip),
              badgeColor: "light-info",
            },
          ],
          // title: item.tip,
        },
        {
          badges: [
            {
              badgeText: dateToString(item.created_at),
              badgeColor: "light-info",
            },
            {
              badgeText: dateToString(item.updated_at),
              badgeColor: "light-warning",
            },
          ],
        },
      ],
    };
  });

import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import Text from "../../../../styleguide/Text";
import { dateToString } from "../../../../utils/dateAndTime";
import receptionConstants from "../../constants/receptionConstants";

const receptionStatusConstants = [
  { value: 1, label: receptionConstants.RECEPTION_OPEN },
  { value: 2, label: receptionConstants.ADDED_INVOICES },
  { value: 3, label: receptionConstants.COUNTING_COMPLETED },
  { value: 4, label: receptionConstants.NIR_GENERATED_WITH_DIFFERENCES },
  { value: 5, label: receptionConstants.RECEPTION_COMPLETED },
  { value: 5, label: receptionConstants.RECEPTION_COMPLETED },
  { value: 6, label: receptionConstants.TRANSFERRED_GOODS },
];

const getStatusLabel = (status: any) => {
  const currentStatus = receptionStatusConstants.find(
    (item) => item.value === parseInt(status),
  );

  return currentStatus?.value
    ? `${currentStatus?.value}. ${currentStatus?.label}`
    : "";
};

export const receiptTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Zona receptie",
    minW: 125,
    // withSearch: true,
  },
  {
    label: "Furnizor",
    value: "provider",
    minW: 200,
    withSearch: true,
  },
  {
    value: "Deschis de",
    minW: 125,
  },
  {
    value: "Data start",
    minW: 125,
  },
  {
    label: "Stadiu",
    value: "stadiu",
    minW: 125,
    withFilter: [
      { value: 1, label: "Toate" },
      { value: 2, label: "Active" },
      { value: 3, label: "Finalizate" },
      { value: 4, label: "Neconforme" },
      { value: 5, label: "Neconforme nefinalizate" },
    ],
  },
];

export const getReceiptsTableData: any = (receiptList: any) =>
  receiptList?.map((receipt: any) => {
    const statusHistory = receipt.statusZona.split(",");

    const receptionStatusWithLabel = statusHistory.map((status: any) =>
      getStatusLabel(status),
    );

    const getLastReceptionStatus = receptionStatusWithLabel
      ? receptionStatusWithLabel[receptionStatusWithLabel.length - 1]
      : "";

    return {
      id: receipt.idStatusReceptie,
      crudData: receipt,
      data: [
        {
          title: receipt.denumireZona,
        },
        {
          title: receipt.numeFurnizor,
        },
        {
          title: receipt.userName,
        },
        {
          badges: [
            {
              badgeText: dateToString(receipt.createdAt),
              badgeColor: "light-info",
            },
          ],
        },
        {
          badges: [
            {
              badgeText: getLastReceptionStatus,
              badgeColor: "light-primary",
              badgeTooltip: (
                <div>
                  {receptionStatusWithLabel.map((status: any) => (
                    <Text key={status}>{status}</Text>
                  ))}
                </div>
              ),
            },
          ],
        },
      ],
    };
  });

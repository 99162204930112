import { KTSVG } from "./KTSVG";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import LabelIcon from "@mui/icons-material/Label";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HistoryIcon from "@mui/icons-material/History";
import PrintIcon from "@mui/icons-material/Print";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";

// Import svg Icon
// Add icon type constant to svgTypes
// add imported svg component to SVGIcons

export type SvgIconType =
  | "KEYBOARD_ARROW_DOWN"
  | "KEYBOARD_ARROW_UP"
  | "DOCUMENT_WITH_DOWNLOAD"
  | "NONCONFORMING"
  | "DELETE"
  | "ADD"
  | "EDIT"
  | "BULLET_LINK"
  | "MENU"
  | "PILL"
  | "DOUBLE_ARROW_RIGHT"
  | "LOCK_CLOSE"
  | "LOCK_OPEN"
  | "RECEIVED_PRODUCTS"
  | "ADD_INVOICE"
  | "NIR_VISUALIZATION"
  | "PREVIEW_NONCONFORMITIES"
  | "DOWNLOAD"
  | "QUARANTINE"
  | "RETURNING_DOCUMENT"
  | "DIFFERENCES"
  | "LABEL"
  | "SUMMARIZE"
  | "HISTORY"
  | "TRANSFER"
  | "PRINT"
  | "RESTORE"
  | "VALIDATE"
  | "WEB_ROLES"
  | "PALM_ROLES"
  | "SPLIT_LIST"
  | "RESYNC"
  | "ACTIVATE";

export const svgTypes: any = {
  KEYBOARD_ARROW_DOWN: KeyboardArrowDownIcon,
  KEYBOARD_ARROW_UP: KeyboardArrowUpIcon,
  DOCUMENT_WITH_DOWNLOAD: "/media/svg/reception/document.svg",
  NONCONFORMING: "/media/svg/reception/nonconforming.svg",
  TRANSFER: "/media/svg/reception/arrows.svg",

  DELETE: DeleteIcon,
  ADD: AddIcon,
  EDIT: EditIcon,
  BULLET_LINK: "/media/icons/bullet_link.svg",
  MENU: "/media/icons/duotune/general/gen025.svg",
  PILL: "/media/icons/duotune/medicine/med002.svg",
  DOUBLE_ARROW_RIGHT: "/media/icons/duotune/arrows/arr080.svg",
  LOCK_CLOSE: "/media/icons/lock_close.svg",
  LOCK_OPEN: "/media/icons/lock_open.svg",
  RECEIVED_PRODUCTS: "/media/svg/reception/inventory.svg",
  ADD_INVOICE: "/media/svg/reception/add-receipt.svg",
  NIR_VISUALIZATION: "/media/svg/reception/analysis.svg",
  PREVIEW_NONCONFORMITIES: "/media/svg/reception/nonconforming.svg",
  DOWNLOAD: DownloadIcon,
  QUARANTINE: "/media/svg/reception/quarantine.svg",
  RETURNING_DOCUMENT: "/media/svg/reception/returning-document.svg",
  DIFFERENCES: "/media/svg/reception/differences.svg",
  LABEL: LabelIcon,
  SUMMARIZE: SummarizeIcon,
  HISTORY: HistoryIcon,
  PRINT: PrintIcon,
  RESTORE: RestartAltIcon,
  VALIDATE: CheckCircleOutlineIcon,
  WEB_ROLES: "/media/svg/administration/web-roles.svg",
  PALM_ROLES: "/media/svg/administration/palm-roles.svg",
  SPLIT_LIST: VerticalSplitIcon,
  RESYNC: "/media/svg/resync.svg",
  ACTIVATE: "/media/svg/activate.svg",
};

const SvgTypesValues = Object.keys(svgTypes);

const SvgIcon = (props: { type: SvgIconType; [key: string]: any }) => {
  const isAvailable = SvgTypesValues.includes(props?.type);

  if (!isAvailable) {
    console.warn("Svg icon is not available, please check svgTypes");
    return <></>;
  }

  const getSvgIcon = () => {
    const SvgPath = svgTypes[props.type];

    if (typeof SvgPath !== "string") {
      return <SvgPath {...props} />;
    }

    return (
      <KTSVG path={SvgPath} className="svg-icon-1" fill="black" {...props} />
    );
  };

  return getSvgIcon();
};

export default SvgIcon;

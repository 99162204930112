import moment from "moment";

export const isTodayDate = (date: Date | string) => {
  return moment().format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD");
};

export const isTomorrowDate = (date: Date | string) => {
  return (
    moment().add(1, "day").format("YYYY-MM-DD") ===
    moment(date).format("YYYY-MM-DD")
  );
};

export const getCurrentDate = () => {
  return moment().format("YYYY-MM-DD");
};

export const getCurrentDateYearShort = () => {
  return moment().format("YY-MM-DD");
};

export const getCurrentTime = () => {
  return moment().format("HH:mm:ss");
};

export const dateToString = (date?: Date | null | string) => {
  if (!date) return "";
  return moment(date).format("DD-MM-YYYY");
};

export const dateForDatabase = (date?: Date | string | null) => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD");
};

export const getDateAndTime = (
  date?: Date | null | string,
  invalidDateLabel = "",
) => {
  if (!date) return invalidDateLabel;
  const dateMoment = moment(date).format("DD-MM-YYYY HH:mm");
  if (dateMoment === "Invalid date") {
    return invalidDateLabel || dateMoment;
  }
  return dateMoment;
};

export const getHoursAndMinutes = (date?: Date | null) => {
  if (!date) return "";
  return moment(date).format("HH:mm");
};

export const getWeekDays = (locale: string) => {
  const baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  const weekDays = [];
  for (let i = 1; i <= 7; i++) {
    weekDays.push({
      id: i,
      name: baseDate.toLocaleDateString(locale, { weekday: "long" }),
    });
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays;
};

export const getDateWithoutNthMonths = (months: number) => {
  return new Date(new Date().setMonth(new Date().getMonth() - months));
};

export const getDateWithoutNthDays = (day: number) => {
  return moment().subtract(day, "days").toDate();
};

// when you run this function you should put date like this "getStartFromSpecificDate("01 April, 2024")" "
export const getStartFromSpecificDate = (date?: Date | null | string) => {
  return moment(date).toDate();
};

export const getLastDayOfMonth = (
  date: Date | string,
  withFullDate?: boolean,
) => {
  const mask = withFullDate ? "YYYY-MM-DD " : "DD";
  const endOfMonth = moment(date).endOf("month").format(mask);
  return endOfMonth;
};

export const getFirstDayOfMonth = (
  date: Date | string,
  withFullDate?: boolean,
) => {
  const mask = withFullDate ? "YYYY-MM-DD " : "DD";
  const startOfMonth = moment(date).startOf("month").format(mask);
  return startOfMonth;
};

export const getDateDifference = (
  comparingDate: Date | string,
  currentDate?: Date | string,
  comparator: "months" | "days" = "months",
) => {
  const currentDateMoment = moment(currentDate);
  const comparingDateMoment = moment(comparingDate);

  //Calculate the difference
  const difference = comparingDateMoment.diff(currentDateMoment, comparator);

  return difference;
};

// when you run this function you get the current year or month, if you want to get the previous year or month you can pass the second argument as a number
export const getCurrentYearOrMonth = (
  type: "year" | "month",
  subtract?: number,
) => {
  const date = moment();

  if (subtract) {
    date.subtract(subtract, type);
  }

  return type === "year" ? date.year() : date.month();
};

export const getYearFromDate = (date: Date | string) => {
  return moment(date).format("YYYY");
};

export const getDaysInMonth = (
  month?: number | string,
  year?: number | string,
) => {
  let adjustedMonth = 0;
  let currentYear = moment().year();

  if (month) {
    adjustedMonth = Number(month) - 1;
  }

  if (year) {
    currentYear = Number(year);
  }

  // Adjust month to be 0-based for moment.js (e.g., January = 0)

  // Get the number of days in the month
  const daysInMonth = moment({
    year: currentYear,
    month: adjustedMonth,
  }).daysInMonth();
  const daysArray = [];

  for (let day = 1; day <= daysInMonth; day++) {
    daysArray.push(
      moment({ year: currentYear, month: adjustedMonth, day }).format("DD"),
    );
  }

  return daysArray;
};

import React from "react";
import TableWidget from "../../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

import { getDeliveryArrangement } from "../../../redux/api/avizPicking.api";

import {
  deliveryArrangementTableHeader,
  getDeliveryArrangementTableData,
} from "./components/tableConfigs/deliveryArrangementTableConfig";
import DeliveryArrangementActionButton from "./components/TableActionsButtonsComponents/DeliveryArrangementActionButton";

function AvizPickingDeliveryArrangement() {
  const dispatch = useAppDispatch();
  const {
    avizPicking: {
      deliveryArrangement: {
        data: { nrTotal, orderPicking },
        isLoading: isLoadingDeliveryArrangement,
      },
    },
  } = useAppSelector((state) => ({
    avizPicking: state.avizPicking,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter
    ) {
      dispatch(
        getDeliveryArrangement({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          status: action.tableFilter?.status ?? 0,
          facturi: action.tableSearch?.facturi ?? "",
          ruta: action.tableSearch?.ruta ?? "",
          deliveryPoint: action.tableSearch?.deliveryPoint ?? "",
          commandNumber: action.tableSearch?.commandNumber ?? "",
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle={"Dispozitii de livrare cu aviz"}
      tableHeaderData={deliveryArrangementTableHeader}
      tableItemsData={getDeliveryArrangementTableData(orderPicking)}
      totalItems={nrTotal}
      borderedRow
      tableLoading={isLoadingDeliveryArrangement}
      ActionButtonsComponent={(props: any) => (
        <DeliveryArrangementActionButton {...props} />
      )}
      handleTableActions={handleTableActions}
    />
  );
}

export default AvizPickingDeliveryArrangement;

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import {
  getProductsWithCustomBcTableData,
  productsWithCustomBcTableHeader,
} from "./tableConfigs/productsWithCustomBcTableConfig";
import TableWidget from "../../components/tables/TableWidget";
import { TableActions } from "../../components/tables/tableContext/TableContext";

import { clearProductsWithCustomSn } from "../../redux/reducer/receipt.reducer";
import { fetchProductsWithCustomSN } from "../../redux/api/receipt.api";
import FormHeader from "../../components/misc/FormHeader";
import { receptionRoutes } from "../../router/routesConstants";
import ProductsWithCustomBcActionButtons from "./TableActionsButtonsComponents/ProductsithCustomBcActionButtons";

function ProductsWithCustomSn() {
  const dispatch = useAppDispatch();

  const {
    receipt: {
      productsWithCustomBc: {
        data: { data: productsWithCustomBc, total: totalProducts },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  useEffect(() => {
    return () => {
      dispatch(clearProductsWithCustomSn());
    };
  }, [dispatch]);

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      dispatch(
        fetchProductsWithCustomSN({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          numeProdus: action.tableSearch?.numeProdus,
          codProdus: action.tableSearch?.codProdus,
          sn: action.tableSearch?.sn,
          tip: action.tableSearch?.tip,
        }),
      );
    }
  };

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <FormHeader
          title="Produse cu cod de bare generat intern"
          tooltipTitle="Produse cu cod de bare generat intern"
          buttonRoute={`/${receptionRoutes.productsWithCustomBc}/${receptionRoutes.productsWithoutSn}`}
          buttonIcon="ADD"
        />
      )}
      ActionButtonsComponent={(props: any) => (
        <ProductsWithCustomBcActionButtons {...props} />
      )}
      tableHeaderData={productsWithCustomBcTableHeader}
      tableItemsData={getProductsWithCustomBcTableData(productsWithCustomBc)}
      tableLoading={isLoading}
      handleTableActions={handleTableActions}
      totalItems={totalProducts}
    />
  );
}

export default ProductsWithCustomSn;

import { useNavigate } from "react-router-dom";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { receptionRoutes } from "../../../../router/routesConstants";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import SvgIcon from "../../../../helpers/SvgIcon";
import CustomButton from "../../../../components/CustomButton";

function MonitorQuarantineActionButtons({
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
}) {
  const navigate = useNavigate();

  const editProduct = () => {
    navigate(
      `/${receptionRoutes.reception}/${receptionRoutes.quarantine}?reception=${crudData.idFluxReceptie}&createdAt=${crudData.createdAt}&page=1`,
    );
  };

  return (
    <div className="text-end">
      <CustomButton
        variant="contained"
        tooltipTitle="Vezi carantina"
        className="mx-1 mb-1"
        onClick={editProduct}>
        <SvgIcon type="QUARANTINE" />
      </CustomButton>
    </div>
  );
}

export default MonitorQuarantineActionButtons;

import CustomButton from "../../../../../components/CustomButton";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../../helpers/apiHelper";
import SvgIcon from "../../../../../helpers/SvgIcon";
import { postDeleteUser } from "../../../../../redux/api/admin.api";
import { useAppDispatch } from "../../../../../redux/hooks";
import { removeUserFromUsers } from "../../../../../redux/reducer/admin.reducer";
import { adminRoutes } from "../../../../../router/routesConstants";

function UsersActionButton({ itemId, crudData, tableContext }: any) {
  const dispatch = useAppDispatch();
  const handleDeleteUser = async () => {
    const resultAction = await dispatch(postDeleteUser(itemId));

    if (
      checkEndpointStatus(resultAction, postDeleteUser) ===
      endpointStatus.fulfilled
    ) {
      if (
        tableContext.tablePagination.page &&
        tableContext.tablePagination.rowsPerPage
      ) {
        dispatch(removeUserFromUsers(itemId));
      }
    }
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        tooltipTitle="Editeaza utilizator"
        className="mt-1 me-1"
        navigate={`/${adminRoutes.users}/${adminRoutes.editUser}?userId=${itemId}`}>
        <SvgIcon type="EDIT" />
      </CustomButton>

      <CustomButton
        variant="contained"
        tooltipTitle="Roluri web"
        className="mt-1 me-1"
        navigate={`/${adminRoutes.users}/${adminRoutes.adminUserWebRoles}?userId=${itemId}&userName=${crudData.userName}`}>
        <SvgIcon type="WEB_ROLES" fill="white" className="svg-icon-2" />
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Roluri palm"
        className="mt-1 me-1"
        navigate={`/${adminRoutes.users}/${adminRoutes.adminUserMobileRoles}?userId=${itemId}&userName=${crudData.userName}`}>
        <SvgIcon type="PALM_ROLES" fill="white" className="svg-icon-2" />
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Stergere"
        color="error"
        className="mt-1 me-1"
        withConfirmationModal={{
          modalTitle: "Stergere utilizator",
          modalSubtitle: `Doriti sa stergeti utilizatorul: ${crudData.userName}?`,
          modalLeftButtonVariant: "outlined",
          modalRightButtonOnClick: handleDeleteUser,
        }}>
        <SvgIcon type="DELETE" />
      </CustomButton>
    </div>
  );
}

export default UsersActionButton;

import { useAppDispatch } from "../../../redux/hooks";
import { ProductsWithCustomSnListInterface } from "../../../redux/interfaces/receipt.interface";
import { TableItemId } from "../../../components/tables/TableWidget";
import CustomButton from "../../../components/CustomButton";
import {
  deleteProductWithCustomSN,
  fetchPrintProductSerialNumber,
  fetchToggleValidateProductWithCustomSN,
} from "../../../redux/api/receipt.api";
import { useNavigate } from "react-router-dom";
import { crudRoutes, receptionRoutes } from "../../../router/routesConstants";
import { setProductWithCustomBc } from "../../../redux/reducer/receipt.reducer";
import SvgIcon from "../../../helpers/SvgIcon";

function ProductsWithCustomBcActionButtons({
  crudData,
  itemId,
}: {
  itemId: TableItemId;
  crudData: ProductsWithCustomSnListInterface;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleValidateProduct = () => {
    dispatch(
      fetchToggleValidateProductWithCustomSN({
        id: itemId,
      }),
    );
  };

  const handleCreateQrPdf = () => {
    dispatch(
      fetchPrintProductSerialNumber({
        id: itemId,
      }),
    );
  };

  const handleDeleteProduct = () => {
    dispatch(
      deleteProductWithCustomSN({
        id: itemId,
      }),
    );
  };

  const editProduct = () => {
    dispatch(setProductWithCustomBc(crudData));

    navigate(
      `/${receptionRoutes.productsWithCustomBc}/${receptionRoutes.productsWithoutSn}/${itemId}/${crudRoutes.create}`,
    );
  };

  return (
    <div className="text-start w-100">
      {!crudData.validated && (
        <CustomButton
          className="me-1  mb-2"
          variant="contained"
          color="success"
          tooltipTitle="Validare"
          withConfirmationModal={{
            modalTitle: "Validare produs",
            modalSubtitle: "Doriti sa validati produsul?",
            modalRightButtonOnClick: handleValidateProduct,
          }}>
          <SvgIcon type="VALIDATE" />
        </CustomButton>
      )}
      {crudData.validated && (
        <CustomButton
          className="me-1  mb-2"
          variant="contained"
          color="primary"
          tooltipTitle="Printeaza"
          withConfirmationModal={{
            modalTitle: "Printare QR",
            modalSubtitle: "Doriti sa printati QR-ul?",
            modalRightButtonOnClick: handleCreateQrPdf,
          }}>
          <SvgIcon type="PRINT" />
        </CustomButton>
      )}

      <CustomButton
        className="me-1  mb-2"
        variant="contained"
        color="primary"
        tooltipTitle="Editare"
        onClick={editProduct}>
        <SvgIcon type="EDIT" />
      </CustomButton>

      <CustomButton
        className="me-1  mb-2"
        variant="contained"
        color={crudData.deleted ? "secondary" : "error"}
        tooltipTitle={crudData.deleted ? "Restaurare" : "Stergere"}
        withConfirmationModal={{
          modalTitle: "Stergere produs",
          modalSubtitle: "Doriti sa stergeti produsul?",
          modalRightButtonOnClick: handleDeleteProduct,
        }}>
        <SvgIcon type={crudData.deleted ? "RESTORE" : "DELETE"} />
      </CustomButton>
    </div>
  );
}

export default ProductsWithCustomBcActionButtons;

import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import { receptionRoutes } from "../../../../../router/routesConstants";

function ReturningTasksActionButton({ itemId, crudData }: any) {
  return (
    <div>
      <CustomButton
        variant="contained"
        tooltipTitle="Formular retur"
        className="mt-1 me-1"
        navigate={`/${receptionRoutes.returns}/${receptionRoutes.createReturnForm}?id=${itemId}`}>
        <SvgIcon type="RETURNING_DOCUMENT" fill="white" />
      </CustomButton>

      <CustomButton
        variant="contained"
        tooltipTitle="Diferente"
        className="mt-1 me-1"
        navigate={`/${receptionRoutes.returns}/${receptionRoutes.returnDifferences}?id=${itemId}&pctDeLivr=${crudData.pctDeLivr}`}>
        <SvgIcon type="DIFFERENCES" fill="white" />
      </CustomButton>
    </div>
  );
}

export default ReturningTasksActionButton;

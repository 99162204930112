import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiRequest,
  errorFormatHandler,
  isDevelop,
} from "../../helpers/apiHelper";

const apiEnv = isDevelop
  ? process.env.REACT_APP_TEST_API_URL
  : process.env.REACT_APP_PUBLIC_API_URL;

export const getUsersActivityReport = createAsyncThunk(
  "/reports/usersActivityReport",
  async (
    params: {
      page: number;
      per_page: number;
      selectedUsers: number[];
      selectedRoles: number[];
      month: number;
      year: number;
      day: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: responseData } = await apiRequest(
        `/usersActivityReport`,
        "POST",
        params,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/reports/usersActivityReport", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getUsersExtraActivities = createAsyncThunk(
  "/reports/usersExtraActivities",
  async (
    params: {
      page: number;
      per_page: number;
      selectedUsers: number[];
      month: number;
      year: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: responseData } = await apiRequest(
        `/usersExtraActivities`,
        "POST",
        params,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/reports/usersExtraActivities", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getReturningProductsToProviderRaports = createAsyncThunk(
  "/reports/returningProductsToProviderRaports",
  async (
    params: {
      page: number;
      per_page: number;
      user: string;
      provider: string;
      dataPV: string;
      docNrAndDate: string;
      productName: string;
      lot: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: responseData } = await apiRequest(
        `/returningProductsToProviderRaports`,
        "GET",
        params,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/reports/returningProductsToProviderRaports", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getDownloadReturningProductsToProviderRaport = createAsyncThunk(
  "/inventory/getDownloadReturningProductsToProviderRaport",
  async (
    {
      idRaport,
      dataPV,
    }: {
      idRaport: string | number | null;
      dataPV: string | null;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window
        .open(
          `${apiEnv}/downloadReturningProductsRaport?idRaport=${idRaport}&dataPV=${dataPV}`,
          "_blank",
        )
        ?.focus();
    } catch (err: any) {
      console.warn(
        "/inventory/getDownloadReturningProductsToProviderRaport",
        err,
      );
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
